import axios from 'axios';
export const state = {
    users:[],
    paginaition:{},
    current_page:1,
    totalUsers : 1,
}

export const mutations = {
    SET_USERS(state, payload) {
        state.users = payload.data;
        state.paginaition = payload.pagination,
        state.current_page = payload.pagination.current_page,
        state.totalUsers = payload.total_users
    },
    SET_PAGE(state,page){
        state.current_page = page;
    }
}

export const getters = {
    // Whether the user is currently logged in.
    getUsers(state) {
        return state.users;
    },
    currentPage(state){
        return state.current_page;
    },
    totalUsers(state){
      return state.totalUsers
    },
}

export const actions = {
    async setUsers({commit,dispatch,getters,rootState},payload){
      let {page,perPage,filter,searchBy} = payload;
      dispatch('global/setLoader',true, { root: true });
      if(page){
        commit('SET_PAGE',page);
      }
      let num = Math.random();
      const response = await axios(`/wp-json/crm/v1/users?page=${getters.currentPage}&per_page=${perPage}&s=${filter}&search_by=${searchBy}&str=${num}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      }
    });
    let users = await response.data;
    commit('SET_USERS',users);
    dispatch('global/setLoader',false, { root: true });
    },

}
