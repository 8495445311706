import { userService } from '../../helpers/fakebackend/user.service';
import router from '../../router/index'
import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null,loader:false };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async login({ dispatch, commit }, { email, password }) {
        try{
            this.state.authfack.loader = true
            commit('loginRequest', { email });
            const data = { username :email,password};
            //let site = 'http://www.staging.mayfairweightlossclinic.co.uk';
            // let site = 'http://www.mayfairweightlossclinic.co.uk';
            //live urls
            // axios.defaults.baseURL = 'http://www.mayfairweightlossclinic.co.uk';
            // localStorage.baseUrl = 'http://www.mayfairweightlossclinic.co.uk';
            
            // staging urls
            // axios.defaults.baseURL = 'https://www.staging.mayfairweightlossclinic.co.uk';
            // localStorage.baseUrl = 'https://www.staging.mayfairweightlossclinic.co.uk';


            let site = process.env.VUE_APP_BASE_URL;
            axios.defaults.baseURL  = process.env.VUE_APP_BASE_URL;
            localStorage.baseUrl    = process.env.VUE_APP_BASE_URL;
            console.log('Base URL set to:', axios.defaults.baseURL);

            const response = await axios.post('/wp-json/crm/v1/login/',data);
            const json  = await response.data;
            if(json.success){
                commit('loginSuccess', json.data);
                dispatch('global/setToken',json.data.token,{ root: true })
                dispatch('global/setSite',site,{ root: true })
                localStorage.user = JSON.stringify(json.data);
                localStorage._site = site;
                localStorage.token = json.data.token;
                router.push({ name: 'Orders', params: { loggedIn: true } });
            }else
            {
                commit('loginFailure', json.error); 
                dispatch('notification/error', json.error, { root: true });
                this.state.authfack.loader = false
            }
        }catch(e){
            dispatch('notification/error', e.response.data.message, { root: true });
            this.state.authfack.loader = false
        }
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};