import axios from "axios";
export const state = {
    dashboardDetails:{},
    currentYearOrders:0,
    previousYearOrders:0,
    currentMonthOrders:0,
    yearHistory:[],
    previousYearHistory:[],
    series : [
      {
        name: "2022",
        type: "column",
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      },
      {
        name: "2019",
        type: "line",
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    ]
}

export const mutations = {
    SET_DATA(state, payload) {
        state.dashboardDetails = payload
    },
    SET_YEAR_HISTORY(state,payload){
        state.yearHistory = payload.yearly_orders
        state.series = [{ name: new Date().getFullYear(),type: "column", data: payload.yearly_orders},{name: new Date().getFullYear()-1,type: "line",data: [23, 32, 27, 500, 27, 600, 27, 400, 600, 31, 21, 16]}] 
        state.currentYearOrders = payload.current_year_orders
        state.previousYearOrders = payload.previous_year_orders
        state.previousYearHistory = payload.previous_yearly_orders
        state.currentMonthOrders = payload.currenth_month_orders
    },
    SET_TODAY_HISTORY(state,payload){
      state.series = [{ name: "Today",type: "column", data: payload}]
    },
    SET_MONTHLY_HISTORY(state,payload){
      state.series = [{ name: "Month",type: "column", data: payload}]
    },
    SET_SERIES(state,data){
        state.series = data
    }
}

export const getters = {
    // Whether the user is currently logged in.
    getData(state) {
        return state.dashboardDetails;
    },
    yearHistory(state){
        return state.yearHistory;
    },
    newSeries(state){
        return state.series;
    },
    currentYearOrders(state){
      return state.currentYearOrders
    },
    previousYearOrders(state){
      return state.previousYearOrders
    },
    currentMonthOrders(state){
      return state.currentMonthOrders
    }
}

export const actions = {
    async setDashboardData({commit,dispatch,rootState}) {
      dispatch('global/setLoader',true, { root: true });
      let num = Math.random();
      const response = await axios('/wp-json/crm/v1/dashboard/?str='+num, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      },
    });
    let json = await response.data;
    commit('SET_DATA', json) 
    dispatch('global/setLoader',false, { root: true });
    },

    async setYearHistory({commit,rootState}) {
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/dashboard/year-history?str='+num, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        },
      });
      let json = await response.data;
      commit('SET_YEAR_HISTORY', json) 
      },
      async setTodayOrdersHistory({commit,rootState}) {
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/dashboard/today-history?str='+num, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        },
       });
       let json = await response.data;
       commit('SET_TODAY_HISTORY', json) 
      },
      async setMonthlyOrdersHistory({commit,rootState}) {
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/dashboard/monthly-history?str='+num, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        },
      });
      let json = await response.data;
      commit('SET_MONTHLY_HISTORY', json) 
      },


      setSeries({commit},data) {
        commit('SET_SERIES', data) 
     }

}