import axios from 'axios';
import Swal from 'sweetalert2';
export const state = {
    productDetails:{},
    patientDetails:{},
    shippingInfo:{},
    shipping:{},
    patient_medical_qa:{},
    patient_confirmation_qa:{},
    product:{},
    order_data:{},
    status:'',
    total_orders:0,
    date:'',
    age_checked : '',
    intervention:{ intervention_issue : '', intervention_issue_reason:''},
    pdfUrl:'',
    interventions : [],
    comments: [],
    gpDetails : {},
}

export const mutations = {
    SET_ORDER(state, payload) {
        const { product_detail,shipment,patient_detail,shipping_information,patient_medical_qa,patient_confirmation_qa,order_status,order_data,total_orders,date,interventions,age_checked,comments,gp_details} = payload;
        state.productDetails = product_detail;
        state.patientDetails = patient_detail;
        state.shippingInfo = shipping_information;
        state.shipping = shipment;
        state.patient_medical_qa = patient_medical_qa; 
        state.patient_confirmation_qa = patient_confirmation_qa;
        state.product = payload.product;
        state.status = order_status
        state.order_data = order_data;
        state.total_orders = total_orders,
        state.date = date,
        state.interventions = interventions,
        state.age_checked = age_checked,
        state.comments = comments,
        state.gpDetails = gp_details
    },
    CHANGESTATUS(state, status) {
        state.status = status;
    },
    CHANGESAGECHECKED(state,payload){
      state.age_checked = payload;
    },
    SETPDFURL(state,payload){
      state.pdfUrl = payload;
    },
    ADDCOMMENT(state,payload){
      state.comments.push(payload);
    },
    REMOVECOMMENT(state,comment_id){
      let index = state.comments.findIndex((comment) => {
        return parseInt(comment.id) === parseInt(comment_id);
      });
      if (index > -1) {
        state.comments.splice(index, 1);
      }
    },
    UPDATE_COMMENT(state,payload){
      let index = state.comments.findIndex((comment) => {
        return parseInt(comment.id) === parseInt(payload.id);
      });

      if (index > -1) {
        state.comments[index].comment = payload.comment;
      }
    },
    INTERVENTION_DELETE(state,id){
      let index = state.interventions.findIndex((intervention) => {
        return parseInt(intervention.id) === parseInt(id);
      });
      if (index > -1) {
        state.interventions.splice(index, 1);
      }
    },
    UPDATE_INTERVENTION(state,payload){
      let index = state.interventions.findIndex((intervention) => {
        return parseInt(intervention.id) === parseInt(payload.id);
      });
      console.log(index);
      if (index > -1) {
        state.interventions[index].reason = payload.reason;
        state.interventions[index].issue = payload.issue;
      }
    },
    ADD_INTERVENTION(state,payload){
      state.interventions.push(payload);
    }
}

export const getters = {
    // Whether the user is currently logged in.
    productDetails(state) {
        return state.productDetails;
    },
    patientDetails(state) {
        return state.patientDetails;
    },
    shippingInfo(state) {
        return state.shippingInfo;
    },
    shipping(state) {
        return state.shipping;
    },
    patientMedicalQa(state){
        return state.patient_medical_qa;
    },
    patientConfirmationQa(state){
        return state.patient_confirmation_qa;
    },
    product(state){
        return state.product;
    },
    orderData(state){
        return state.order_data;
    },
    getPDFURL(state){
      return state.pdfUrl;
    },
    comments(state){
      return state.comments;
    },
    gpDetails(state){
      return state.gpDetails;
    },
    interventions(state){
      return state.interventions;
    }
}

export const actions = {
      async setOrder({commit,dispatch,rootState},orderID){
        dispatch('global/setLoader',true, { root: true });
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/orders/'+orderID+'?str='+num, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      let order = await response.data;
      commit('SET_ORDER', order)
      dispatch('global/setLoader',false, { root: true });
      },
      async editOrder({commit,rootState},data){
        // dispatch('global/setLoader',true, { root: true });
        let {orderID} = data
        console.log(commit)
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/order-edit/'+orderID+'?str='+num, {
        method: 'POST',
        data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
       let order = await response.data;
       console.log(order)
      },
      async editStatus({commit,rootState},data){
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/change-order-status?str='+num, {
        method: 'POST',
        data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      let res = await response.data;
      commit('CHANGESTATUS',data.status)
      console.log(res);
      Swal.fire(
        'Status Changed',
        res.message,
        'success'
      )
      },
      async editIntervention({commit,rootState},payload){
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/intervention/'+payload.id+'?str='+num,{
        method:'POST',
        data:{...payload},
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      let res = await response.data;
      if(res.success){
        commit('UPDATE_INTERVENTION',res.data);
        Swal.fire(
          'Intervention Updated',
          res.message,
          'success'
        )
       }else{
        Swal.fire(
          'Failed',
          res.message,
          'error'
        )
       }
       return res;
      },
      async deleteIntervention({commit,rootState},payload){
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/intervention/'+payload.intervention_id+'?str='+num,{
        method:'DELETE',
        data:{...payload},
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      
      let res = await response.data;
      if(res.success){
        commit('INTERVENTION_DELETE',payload.intervention_id);
        Swal.fire(
          'Intervention Deleted',
          res.message,
          'success'
        )
       }else{
        Swal.fire(
          'Failed',
          res.message,
          'error'
        )
       }
       return res;
      },
      async editAgeChecked({rootState},data){
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/edit_age_checked?str='+num, {
        method: 'POST',
        data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      let res = await response.data;
      console.log(res);
        Swal.fire(
          'Age Check Changed',
          res.message,
          'success'
        )
      },
      async downloadPdf({commit,rootState},data){
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/download-pdf?str='+num, {
        method: 'POST',
        data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      let res = response.data;
      commit('SETPDFURL',res.message)
      return res;
      },
      async addIntervention({commit,rootState},payload){
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/intervention?str='+num,{
        method:'POST',
        data:{...payload},
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      let res = await response.data;
      if(res.success){
        commit('ADD_INTERVENTION',res.data);
        Swal.fire(
          'Intervention Saved',
          res.message,
          'success'
        )
       }else{
        Swal.fire(
          'Failed',
          res.message,
          'error'
        )
       }
       return res;
      },
      async addComment({commit,rootState},data){
        let num = Math.random();
        const response = await axios('/wp-json/crm/v1/comments?str='+num, {
        method: 'POST',
        data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        }
      });
      let res = response.data;
      if(res.success){
        commit('ADDCOMMENT',res.data);
        Swal.fire(
          'Success',
            res.message,
          'success'
        )
      }else{
        Swal.fire(
          'Failed',
            res.message,
          'error'
        )
      }
      return res;
      },
      async editComment({commit,rootState},payload){
      console.log(payload);
      let num = Math.random();
      const response = await axios('/wp-json/crm/v1/comments/'+payload.id+'?str='+num,{
      method:'PUT',
      data:{...payload},
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      }
    });
    let res = await response.data;
    if(res.success){
      commit('UPDATE_COMMENT',res.data);
      Swal.fire(
        'Updated',
        res.message,
        'success'
      )
     }else{
      Swal.fire(
        'Failed',
        res.message,
        'error'
      )
     }
      },
      async deleteComment({commit,rootState},data){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let num = Math.random();
          const response = await axios('/wp-json/crm/v1/comments?str='+num, {
          method: 'DELETE',
          data,
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + rootState.global.token,
            }
          });
          let res = response.data;
          commit('REMOVECOMMENT',data.comment_id);
          Swal.fire(
            'Deleted!',
            'Comment has been deleted.',
            'success'
          );
          return res;
        }
      })
      },
}