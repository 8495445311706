import axios from 'axios';

export const state = {
    orders:[],
    paginaition:{},
    currentPage:1,
    totalOrdes : 1,
    loader : false
}

export const mutations = {
    SET_ORDERS(state, payload) {
        state.orders = payload.data;
    },
    SET_LOADER(state){
        state.loader = true; 
    },
    REMOVE_LOADER(state){
        state.loader = false;
    }
}

export const getters = {
    // Whether the user is currently logged in.
    orders(state) {
        return state.orders;
    },
    currentPage(state){
        return state.currentPage;
    },
    totalOrdes(state){
      return state.totalOrdes
    },
    loader(state){
        return state.loader;
    }
}

export const actions = {
    async getInterventionOrders({commit,rootState},payload){
      const {filter,product,searchBy,variation,filterFrom,filterTo,interventionIssue,bmi_from,bmi_to} = payload; 
      commit('SET_LOADER'); 
      let num = Math.random();
      const response = await axios('/wp-json/crm/v1/intervention?intervension_issue='+interventionIssue+'&filter_from='+filterFrom+'&filter_to='+filterTo+'&product='+product+'&variation='+variation+'&s='+filter+'&search_by='+searchBy+'&bmi_from='+bmi_from+'&bmi_to='+bmi_to+'&str='+num, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      }
    });
    let orders = await response.data;
    commit('SET_ORDERS', orders) 
    commit('REMOVE_LOADER'); 
    }
}

// ?page='+page+'&per_page='+per_page+'&s='+filter+'&search_by='+searchBy+'&order_status='+orderStatus+'&filter_from='+filterFrom+'&filter_to='+filterTo+'&product='+product+'&variation='+variation+'