 import axios from 'axios';
 export const state = {
    loader:true,
    currency:'£',
    perPage:20,
    products:[],
    productsFilterOptions:[{value:"all",text:"All"}],
    productVariationOptions:[{value:"all",text:"All"}],
    site:localStorage.getItem('_site'),
    token:localStorage.getItem('token')
}

export const mutations = {
    SET_URL(state, payload) {
        state.siteUrl = payload
    },
    SET_LOADER(state,payload){
        state.loader = payload; 
    },
    SET_TOKEN(state,token){
        state.token = token
    },
    SET_SITE(state,site){
        state.site = site
    },
    SET_PRODUCTS(state,products){
        state.products = products;
        for(let item of products){
            state.productsFilterOptions.push({value:item.id,text:item.title.rendered});
        }
    },
    SET_VARIATIONS(state,variations){
        state.productVariationOptions =  variations;
    }
}

export const getters = {
    // Whether the user is currently logged in.
    siteUrl(state) {
        return state.siteUrl;
    },
    loader(state){
        return state.loader;
    },
    currency(state){
        return state.currency;
    },
    perPage(state){
        return state.perPage;
    },
    site(state){
        return state.site;
    },
    user(state, getters, rootState){
        return rootState.authfack.user;
    },
    productsFilterOptions(state){
        return state.productsFilterOptions;
    },
    productVariationOptions(){
        return state.productVariationOptions;
    }
}

export const actions = {
     setUrl({commit},url){
        if(url){
            commit('SET_URL', url)
        }
    },
    setLoader({commit},type){
        commit('SET_LOADER',type)
    },
    setToken({commit},token){
        commit('SET_TOKEN',token)
    },
    setSite({commit},site){
       commit('SET_SITE',site)
    },
    async getProducts({commit}){
        let response = await axios.get('/wp-json/wp/v2/products?_fields[]=acf&_fields[]=id&_fields[]=title');
        let json = await response.data;
        commit('SET_PRODUCTS',json);
    },
    changePrductVariations({commit,state},event){
        let product_id = event; 
        if(product_id == 'all'){
          state.productVariationOptions = [{value:"all",text:"All"}];
          return false;
        }
        let product = state.products.find(item => item.id == product_id);
        let { variation }  = product.acf;
        let variationOptions = [{value:"all",text:"All"}];
        if(variation){
        for(let item of variation){
          for(let option of item.variation_option){
            variationOptions.push({value:option.name,text:option.caption_6});
          }
        }
       }
       commit('SET_VARIATIONS',variationOptions);
    //    setTimeout(()=>{
    //      this.variation_loader = false;
    //    },1000)
     }

}
