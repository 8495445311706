import axios from 'axios';
export const state = {
    userOrders:[],
    userData:{}
}

export const mutations = {
    SET_USER_ORDERS(state, payload) {
        state.userOrders = payload
        state.userData = payload.user_data;
    }
}

export const getters = {
    products(state) {
        return state.userOrders;
    },
    userData(state){
        return state.userData
    }
}

export const actions = {
    async getUserOrders({commit,dispatch},options){
        let { userId,filterFrom,filterTo } = options;
        let num = Math.random();
        dispatch('global/setLoader',true, { root: true });
        const response = await axios(`/wp-json/crm/v1/user-orders/${userId}?filter_from=${filterFrom}&filter_to=${filterTo}&str=${num}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.token,
            },
          });
          let json = await response.data;
          commit('SET_USER_ORDERS',json);
          dispatch('global/setLoader',false, { root: true });
    },
}
