<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import {
    globalMethods,
  } from "@/state/helpers";
import appConfig from "@/app.config";
import axios from 'axios';
export default {
  name: "app",
  created(){
    axios.interceptors.response.use(undefined, function (err) {
    return new Promise(function (resolve, reject) {
      if (err.response.status === 401 && err.response.config) {
        console.log('Expired Token',resolve,reject); 
        location = '/logout';
      }
      throw err;
    });
  });
  this.getProducts();
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  methods:{
    ...globalMethods
  }
};
</script>