import axios from 'axios';

export const state = {
    // orders: localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : [],
    orders: [],
    paginaition:{},
    currentPage:1,
    totalOrdes : 1,
    allOrders : [],
    loader : false
}

export const mutations = {
    SET_ORDERS(state, payload) {
        // localStorage.setItem('orders',JSON.stringify(payload.data));
        state.orders = payload.data;
        state.paginaition = payload.pagination,
        state.currentPage = payload.pagination.current_page,
        state.totalOrdes = payload.total_orders,
        state.allOrders =  payload.all_orders
    },
    SET_LOADER(state){
        state.loader = true; 
    },
    REMOVE_LOADER(state){
        state.loader = false;
    }
}

export const getters = {
    orders(state) {
        return state.orders;
    },
    currentPage(state){
        return state.currentPage;
    },
    totalOrdes(state){
      return state.totalOrdes
    },
    loader(state){
        return state.loader;
    },
    allOrders(state){
      return state.allOrders;
    }
}

export const actions = {
    async setOrders({commit,rootState},payload){
      const { page ,per_page,filter,searchBy,orderStatus,paymentStatus,filterFrom,filterTo,product,variation,bmi_from,bmi_to} = payload; 
      commit('SET_LOADER'); 
      let num = Math.random();
      const response = await axios('/wp-json/crm/v1/orders?page='+page+'&per_page='+per_page+'&s='+filter+'&search_by='+searchBy+'&order_status='+orderStatus+'&payment_status='+paymentStatus+'&filter_from='+filterFrom+'&filter_to='+filterTo+'&product='+product+'&variation='+variation+'&bmi_from='+bmi_from+'&bmi_to='+bmi_to+'&str='+num, {
      method: 'GET',
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      }
    });
    let orders = await response.data;
    console.log(orders)
    commit('SET_ORDERS', orders) 
    commit('REMOVE_LOADER'); 
    },
    async sendEmail({rootState},payload){ 
      try {
      let num = Math.random();
      const response = await axios('/wp-json/crm/v1/email?str='+num, {
      method: 'POST',
      data: {
        ...payload
      },
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      }
      });
      let json = await response.data;
      return json;
      }catch(error){
        return error.response.data;
      }
      
    },
    getGPData({rootState},orderId){
      return rootState.orders.orders.find(x => x.ID === orderId).gp_details;
    },
    async sendEmailGP({rootState},payload){
      console.log(payload);
      let num = Math.random();
      const response = await axios('/wp-json/crm/v1/gp-email?str='+num, {
      method: 'POST',
      data: {
        ...payload
      },
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      }
      });
      let json = await response.data;
      return json;
    },
    async getAllCSVOrders({ rootState }, payload) {
    
        let response = await axios.post(`/wp-json/crm/v1/download-orders-csv?page=${payload.page}`, {
          order_ids: payload.data,
        }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + rootState.global.token,
          },
        });
        const data = response.data;
        return data;
    }    
}










/*


async getAllCSVOrders({ rootState }, payload) {
  let start = 1;
  let isFirstChunk = true;
  let continueLoop = true;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  while (continueLoop) {
      let response = await axios.post(`/wp-json/crm/v1/download-orders-csv?page=${start}`, {
        order_ids: payload,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + rootState.global.token,
        },
      });
      const data = response.data.data;
      console.log(response.data.percentage)
      if (data.length === 0) {
          continueLoop = false;
          break; 
      }

      if (isFirstChunk) {
          XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 });
          isFirstChunk = false;
      } else {
          XLSX.utils.sheet_add_json(worksheet, data, { origin: -1 });
      }

      console.log(`Downloaded ${data.length} records`);

      ++start;
  }

  // Manually adjust column widths based on the content in each column
  const maxContentLengths = {};
  const columnHeaders = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

  for (let row = 0; row <= XLSX.utils.decode_range(worksheet['!ref']).e.r; row++) {
      for (let col = 0; col < columnHeaders.length; col++) {
          const cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: col })]?.w || '';
          const header = columnHeaders[col];
          maxContentLengths[header] = Math.max(cellValue.length, maxContentLengths[header] || 0);
      }
  }

  const columnCount = columnHeaders.length;
  for (let col = 0; col < columnCount; col++) {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: col })].w = maxContentLengths[columnHeaders[col]];
  }

  // Write the workbook to a binary buffer
  const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

  // Convert buffer to a Blob and create a download link
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const downloadLink = document.createElement('a');
  const url = URL.createObjectURL(blob);

  downloadLink.href = url;
  downloadLink.download = 'products.xlsx';

  // Trigger the download
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  console.log('Download complete');
  } */