import axios from "axios";
export const state = {
    userDetails:{},
    patient:{},
    shippingInfo:{},
}

export const mutations = {
    SET_USER_DATA(state, payload) {
        state.userDetails = payload.user
        state.patient = payload.patient
        state.shippingInfo = payload.shipping_info
    },
}

export const getters = {
    // Whether the user is currently logged in.
    userData(state) {
        return state.userDetails;
    },
    patient(state){
        return state.patient;
    },
    shippingInfo(state){
        return state.shippingInfo;
    }
}

export const actions = {
    async setUserdData({commit,dispatch,rootState},userId) {
      dispatch('global/setLoader',true, { root: true });
      let num = Math.random();
      const response = await axios('/wp-json/crm/v1/users/'+userId+'?str='+num, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',    
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + rootState.global.token,
      },
    });
    let json = await response.data;
    commit('SET_USER_DATA', json);
    dispatch('global/setLoader',false, { root: true });
    },

}
